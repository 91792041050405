<template>
  <div v-if="!loadingError" class="container">
    <div>
      <h1 class="titleName">{{ data?.workerName }}</h1>
    </div>
    <div>
      <div v-if="isLoading" class="loader">
        <div class="loader-inner"></div>
      </div>

      <div v-else class="inputWrapper">
        <input
          class="input"
          v-model.number="donateSum"
          required
          type="number"
          placeholder="введите сумму доната"
        />

        <div class="payment-options">
          <div
            v-for="(option, index) in data?.bankServices"
            :key="index"
            class="payment-card"
            :class="{ selected: selectedBankService === option.bankService }"
            @click="paymentHendler(option.bankService)"
          >
            <input
              type="radio"
              v-model="selectedBankService"
              :value="option.bankService"
              name="payment-method"
            />
            <div>
              <img :src="getImage(option.bankService)" alt="Payment Method" />
            </div>
          </div>
          <span class="error" v-if="error">{{ warningMessage }}</span>
        </div>
      </div>
    </div>
  </div>
  <InfoBlock
    v-else
    color="rgb(208, 100, 100)"
    text="произошла ошибка"
    :loadingError="loadingError"
  />
</template>

<script lang="ts" setup>
  import { useRoute } from 'vue-router'
  import InfoBlock from '../components/InfoBlock.vue'
  import { Ref, onMounted, ref } from 'vue'
  import { Params } from '@/types'
  import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
  import { AcquiringClient } from '@/generated/acquiring_donate.client'
  import {
    CreatePaymentRequest,
    GetInfoRequest,
    GetInfoResponse,
  } from '@/generated/acquiring_donate'

  const transport = new GrpcWebFetchTransport({
    baseUrl: window.location.origin,
  })
  const acquiringClient = new AcquiringClient(transport)

  const route: any = useRoute()
  const error = ref(false)
  const isLoading = ref(false)
  const loadingError = ref('')
  const warningMessage = ref('')
  const data = ref<GetInfoResponse>()
  const donateSum = ref()
  const selectedBankService: Ref<number | null> = ref(null)
  const queryParams = ref<Params>({
    workerId: 0,
    serviceId: 0,
    hash: '',
  })

  const getImage = (bankService: number) => {
    switch (bankService) {
      case 0:
        return require('../assets/images/mir.svg')
      case 1:
        return require('../assets/images/sbp.svg')
      default:
        return ''
    }
  }

  const selectOption = (bankService: number | null) => {
    selectedBankService.value = bankService
  }

  const loadData = () => {
    const request = GetInfoRequest.create({ ...queryParams.value })
    acquiringClient
      .getInfo(request)
      .then(({ response }) => {
        data.value = response
      })
      .catch((e) => {
        loadingError.value = decodeURI(e.message)
      })
  }

  const parse = () => {
    if (route.query.w && route.query.g && route.query.h) {
      queryParams.value.workerId = parseInt(route.query.w)
      queryParams.value.serviceId = parseInt(route.query.g)
      queryParams.value.hash = route.query.h
      loadData()
    } else {
      loadingError.value = 'что-то пошло не так'
    }
  }

  const paymentHendler = (bankService: number) => {
    if (donateSum.value) {
      isLoading.value = true
      selectOption(bankService)

      const request = CreatePaymentRequest.create({
        ...queryParams.value,
        amount: donateSum.value,
        bankService,
      })

      acquiringClient
        .createPayment(request)
        .then(({ response }) => {
          window.location.href = response.paymentUrl
          error.value = false
        })
        .catch((e) => {
          warningMessage.value = decodeURI(e.message)
          error.value = true
        })
        .finally(() => {
          isLoading.value = false
        })
    } else {
      warningMessage.value = 'Минимальная сумма 1 Руб'
      error.value = true
    }
  }

  onMounted(() => {
    parse()
  })
</script>
