/* eslint-disable */
// @generated by protobuf-ts 2.9.3 with parameter generate_dependencies,eslint_disable,ts_nocheck
// @generated from protobuf file "acquiring_donate.proto" (package "DonateAcquiring", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message DonateAcquiring.GetInfoRequest
 */
export interface GetInfoRequest {
    /**
     * @generated from protobuf field: int32 service_id = 1;
     */
    serviceId: number;
    /**
     * @generated from protobuf field: int32 worker_id = 2;
     */
    workerId: number;
    /**
     * @generated from protobuf field: string hash = 3;
     */
    hash: string;
}
/**
 * @generated from protobuf message DonateAcquiring.GetInfoResponse
 */
export interface GetInfoResponse {
    /**
     * @generated from protobuf field: string worker_name = 1;
     */
    workerName: string;
    /**
     * @generated from protobuf field: string service_name = 2;
     */
    serviceName: string;
    /**
     * Способы пополнения
     *
     * @generated from protobuf field: repeated DonateAcquiring.GetInfoResponse.BankServiceItem bank_services = 3;
     */
    bankServices: GetInfoResponse_BankServiceItem[];
}
/**
 * @generated from protobuf message DonateAcquiring.GetInfoResponse.BankServiceItem
 */
export interface GetInfoResponse_BankServiceItem {
    /**
     * Тип банковского сервиса
     *
     * 0 - карта банка
     * 1 - СБП
     *
     * @generated from protobuf field: int32 bank_service = 1;
     */
    bankService: number;
    /**
     * Человеческое название
     *
     * @generated from protobuf field: string bank_service_name = 2;
     */
    bankServiceName: string;
}
/**
 * @generated from protobuf message DonateAcquiring.CreatePaymentRequest
 */
export interface CreatePaymentRequest {
    /**
     * @generated from protobuf field: int32 service_id = 1;
     */
    serviceId: number;
    /**
     * @generated from protobuf field: int32 worker_id = 2;
     */
    workerId: number;
    /**
     * @generated from protobuf field: double amount = 3;
     */
    amount: number;
    /**
     * @generated from protobuf field: int32 bank_service = 4;
     */
    bankService: number;
    /**
     * @generated from protobuf field: string hash = 5;
     */
    hash: string;
}
/**
 * @generated from protobuf message DonateAcquiring.CreatePaymentResponse
 */
export interface CreatePaymentResponse {
    /**
     * @generated from protobuf field: string payment_url = 1;
     */
    paymentUrl: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetInfoRequest$Type extends MessageType<GetInfoRequest> {
    constructor() {
        super("DonateAcquiring.GetInfoRequest", [
            { no: 1, name: "service_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "worker_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInfoRequest>): GetInfoRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.serviceId = 0;
        message.workerId = 0;
        message.hash = "";
        if (value !== undefined)
            reflectionMergePartial<GetInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInfoRequest): GetInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 service_id */ 1:
                    message.serviceId = reader.int32();
                    break;
                case /* int32 worker_id */ 2:
                    message.workerId = reader.int32();
                    break;
                case /* string hash */ 3:
                    message.hash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 service_id = 1; */
        if (message.serviceId !== 0)
            writer.tag(1, WireType.Varint).int32(message.serviceId);
        /* int32 worker_id = 2; */
        if (message.workerId !== 0)
            writer.tag(2, WireType.Varint).int32(message.workerId);
        /* string hash = 3; */
        if (message.hash !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.hash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DonateAcquiring.GetInfoRequest
 */
export const GetInfoRequest = new GetInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInfoResponse$Type extends MessageType<GetInfoResponse> {
    constructor() {
        super("DonateAcquiring.GetInfoResponse", [
            { no: 1, name: "worker_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "service_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bank_services", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetInfoResponse_BankServiceItem }
        ]);
    }
    create(value?: PartialMessage<GetInfoResponse>): GetInfoResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.workerName = "";
        message.serviceName = "";
        message.bankServices = [];
        if (value !== undefined)
            reflectionMergePartial<GetInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInfoResponse): GetInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string worker_name */ 1:
                    message.workerName = reader.string();
                    break;
                case /* string service_name */ 2:
                    message.serviceName = reader.string();
                    break;
                case /* repeated DonateAcquiring.GetInfoResponse.BankServiceItem bank_services */ 3:
                    message.bankServices.push(GetInfoResponse_BankServiceItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string worker_name = 1; */
        if (message.workerName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.workerName);
        /* string service_name = 2; */
        if (message.serviceName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.serviceName);
        /* repeated DonateAcquiring.GetInfoResponse.BankServiceItem bank_services = 3; */
        for (let i = 0; i < message.bankServices.length; i++)
            GetInfoResponse_BankServiceItem.internalBinaryWrite(message.bankServices[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DonateAcquiring.GetInfoResponse
 */
export const GetInfoResponse = new GetInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInfoResponse_BankServiceItem$Type extends MessageType<GetInfoResponse_BankServiceItem> {
    constructor() {
        super("DonateAcquiring.GetInfoResponse.BankServiceItem", [
            { no: 1, name: "bank_service", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "bank_service_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInfoResponse_BankServiceItem>): GetInfoResponse_BankServiceItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bankService = 0;
        message.bankServiceName = "";
        if (value !== undefined)
            reflectionMergePartial<GetInfoResponse_BankServiceItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInfoResponse_BankServiceItem): GetInfoResponse_BankServiceItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 bank_service */ 1:
                    message.bankService = reader.int32();
                    break;
                case /* string bank_service_name */ 2:
                    message.bankServiceName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInfoResponse_BankServiceItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 bank_service = 1; */
        if (message.bankService !== 0)
            writer.tag(1, WireType.Varint).int32(message.bankService);
        /* string bank_service_name = 2; */
        if (message.bankServiceName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bankServiceName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DonateAcquiring.GetInfoResponse.BankServiceItem
 */
export const GetInfoResponse_BankServiceItem = new GetInfoResponse_BankServiceItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePaymentRequest$Type extends MessageType<CreatePaymentRequest> {
    constructor() {
        super("DonateAcquiring.CreatePaymentRequest", [
            { no: 1, name: "service_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "worker_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "bank_service", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreatePaymentRequest>): CreatePaymentRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.serviceId = 0;
        message.workerId = 0;
        message.amount = 0;
        message.bankService = 0;
        message.hash = "";
        if (value !== undefined)
            reflectionMergePartial<CreatePaymentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePaymentRequest): CreatePaymentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 service_id */ 1:
                    message.serviceId = reader.int32();
                    break;
                case /* int32 worker_id */ 2:
                    message.workerId = reader.int32();
                    break;
                case /* double amount */ 3:
                    message.amount = reader.double();
                    break;
                case /* int32 bank_service */ 4:
                    message.bankService = reader.int32();
                    break;
                case /* string hash */ 5:
                    message.hash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePaymentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 service_id = 1; */
        if (message.serviceId !== 0)
            writer.tag(1, WireType.Varint).int32(message.serviceId);
        /* int32 worker_id = 2; */
        if (message.workerId !== 0)
            writer.tag(2, WireType.Varint).int32(message.workerId);
        /* double amount = 3; */
        if (message.amount !== 0)
            writer.tag(3, WireType.Bit64).double(message.amount);
        /* int32 bank_service = 4; */
        if (message.bankService !== 0)
            writer.tag(4, WireType.Varint).int32(message.bankService);
        /* string hash = 5; */
        if (message.hash !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.hash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DonateAcquiring.CreatePaymentRequest
 */
export const CreatePaymentRequest = new CreatePaymentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreatePaymentResponse$Type extends MessageType<CreatePaymentResponse> {
    constructor() {
        super("DonateAcquiring.CreatePaymentResponse", [
            { no: 1, name: "payment_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreatePaymentResponse>): CreatePaymentResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paymentUrl = "";
        if (value !== undefined)
            reflectionMergePartial<CreatePaymentResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreatePaymentResponse): CreatePaymentResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string payment_url */ 1:
                    message.paymentUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreatePaymentResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string payment_url = 1; */
        if (message.paymentUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.paymentUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DonateAcquiring.CreatePaymentResponse
 */
export const CreatePaymentResponse = new CreatePaymentResponse$Type();
/**
 * @generated ServiceType for protobuf service DonateAcquiring.Acquiring
 */
export const Acquiring = new ServiceType("DonateAcquiring.Acquiring", [
    { name: "GetInfo", options: {}, I: GetInfoRequest, O: GetInfoResponse },
    { name: "CreatePayment", options: {}, I: CreatePaymentRequest, O: CreatePaymentResponse }
]);
